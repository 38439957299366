import { Container, Grid } from "@mui/material";
import React from "react";
import Check from "../../../../assets/images/icon chec.png";
import { DataImage, SectionHeading } from "../../../Styles/style";
import RoadCard from "../../roadcard";
import Paragraph from "../../Paragraph";

const RoadMap = () => {
  return (
    <div>
      <Container maxWidth="lg" id="roadmap"  sx={{paddingTop:"100px"}}>
        <Grid container>
          <Grid item xs={12}>
            <SectionHeading ta="center" p="30px 0px 60px">
              Our Roadmap
            </SectionHeading>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <SectionHeading
                fs="30px"
                c="#19FA9C"
                fm="PoppinsM"
                ta="left"
                p="0 0 10px 50px"
                sx={{ borderBottom: "2px dashed #19FA9C" }}
              >
                Phase 1
              </SectionHeading>
              <RoadCard
                backbg="none"
                textAlign="left"

                //     data={
                //         [['- Design & creation'],
                //        ['- Website launch'],
                //        ['- Contract Deployment'],
                //        ['- Anonsol Bot launch'],
                //        ['- Lorem ipsum dolor sit amet'],
                //        ['- Lorem ipsum dolor']
                // ]}
              >
                <Paragraph
                textAlign="left"
                  p="10px 0px"
                  fs="14px"
                  m="1px 0px"
                  color="#19FA9C"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  - Design & creation &nbsp; <DataImage mw="20px" src={Check} />
                </Paragraph>
                <Paragraph
                textAlign="left"
                  p="10px 0px"
                  fs="14px"
                  m="1px 0px"
                  color="#19FA9C"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  - Website launch &nbsp; <DataImage mw="20px" src={Check} />
                </Paragraph>
                <Paragraph
                textAlign="left"
                  p="10px 0px"
                  fs="14px"
                  m="1px 0px"
                  color="#19FA9C"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  - Contract Deployment &nbsp;{" "}
                  <DataImage mw="20px" src={Check} />
                </Paragraph>
                <Paragraph
                textAlign="left"
                  p="10px 0px"
                  fs="14px"
                  m="1px 0px"
                  color="#19FA9C"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  - Anonsol Bot launch &nbsp;{" "}
                  <DataImage mw="20px" src={Check} />
                </Paragraph>
                <Paragraph
                textAlign="left" p="10px 0px" fs="14px" m="1px 0px">
                  - Security Audit{" "}
                </Paragraph>
                <Paragraph
                textAlign="left"
                  p="10px 0px"
                  fs="14px"
                  m="1px 0px"
                  color="#19FA9C"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  - Community Building &nbsp;{" "}
                  <DataImage mw="20px" src={Check} />
                </Paragraph>
              </RoadCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SectionHeading
                sx={{ borderBottom: "2px dashed #fff" }}
                fs="30px"
                fm="PoppinsM"
                ta="left"
                p="0 0 10px 50px"
              >
                Phase 2
              </SectionHeading>
              <RoadCard backbg="none" textAlign="left">
                <Paragraph
                textAlign="left"
                  p="10px 0px"
                  fs="14px"
                  m="1px 0px"
                  color="#19FA9C"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  - Mixer launch &nbsp; <DataImage mw="20px" src={Check} />
                </Paragraph>
                <Paragraph
                textAlign="left"
                  p="10px 0px"
                  fs="14px"
                  m="1px 0px"
                  color="#19FA9C"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  - Card Release &nbsp; <DataImage mw="20px" src={Check} />
                </Paragraph>
                <Paragraph
                textAlign="left" p="10px 0px" fs="14px" m="1px 0px">
                  - Bug Fixes
                </Paragraph>
                <Paragraph
                textAlign="left" p="10px 0px" fs="14px" m="1px 0px">
                  - Partnership Exploration{" "}
                </Paragraph>
              </RoadCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SectionHeading
                sx={{ borderBottom: "2px dashed #fff" }}
                fs="30px"
                fm="PoppinsM"
                ta="left"
                p="0 0 10px 50px"
              >
                Phase 3
              </SectionHeading>
              <RoadCard
                backbg="none"
                textAlign="left"
                data={[
                  ["- Global Expansion "],
                  ["- Continuous Development"],
                  ["- User Education"],
                  ["- Feature Enhancements"],
                  ["- Strategic Collaborations"],
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SectionHeading
                sx={{ borderBottom: "2px dashed #fff" }}
                fs="30px"
                fm="PoppinsM"
                ta="left"
                p="0 0 10px 50px"
              >
                Continue...
              </SectionHeading>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default RoadMap;
