import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import React from 'react';

const BoxStyle = styled(Box)`
    /* background: ${props => props.backbg? props.backbg : 'linear-gradient( to top, #000000, #1C1C1C )'}; */
    min-height: ${props => props.minh? props.minh : '120px'};
    background-color:  ${props => props.backbg? props.backbg : 'black'};
    max-width: ${props => props.mw? props.mw : '250px'} ;
    border-radius: 20px 20px;
    padding: ${props => props.pd? props.pd : '20px'};
    margin: 20px auto;
    display: grid;
    place-items: center;
`
const Para = styled(Typography)`
    /* font-family: poppins-regular; */
    color: ${props => props.c? props.c : 'white'};
    text-align: ${props => props.ta? props.ta : 'left'};
    font-size: 14px;
    padding: 10px 0px;
    /* text-align: center; */
    margin: ${props => props.m? props.m : ''};
    :hover{
        /* color: #9945FF; */
    }
`
const Disc  = (props)=> <Para textAlign={props.ta}>{props.detail}</Para>
const RoadCard = (props) => {
    const{ data, children} = props;
    return(
        <BoxStyle xs={12} md={4} {...props} data-aos="zoom-in-up" data-aos-duration = '1000'>
            {/* <Para1 variant='h6' {...props}>{tag}</Para1> */}
            <div>
                <Para {...props}>{children}</Para>
            {data?.map((val, ind)=> {
                return <Disc {...props} key={ind} detail={val[0]}/>
            })}
            </div>
        </BoxStyle>
    )
}

export default RoadCard;