import React from "react";
import "./style.js";
import { Lable } from "./style.js";
import styled from "@emotion/styled";
import { InputField } from "../../Styles/style.js";
// const InputField = styled.input`
//   font-family: OpenSans;
//   padding: 10px;
//   background-color: #fff;
//   color: #000;
//   width: ${props => props.w ? props.w :"49%"};
//   height: 20px;
//   border: 1px solid #d7d7d7;
//   border-radius: ${props=> props.br ? props.br : "0px"};
//   :focus {
//     outline: 1px solid var(--primary-color);
//   }
//   @media(max-width : 599px){
//     flex-direction: ${props=> props.fd ? props.fd : "row"};
//     align-items: flex-start;
//     width: ${props => props.w599};
//   }
// `;
const P = styled.p`
  
  color: red;
  font-size: 10px;

`
const InputForm = ({
  value,
  value1,
  amountCheck,
  addressCheck,
  amountStatus,
  addressStatus,
}) =>  {
  return (
    <form style={{width:"100%"}}>
      {!value && !value1 ? (
        <P className={"showP"}>please fill this field</P>
      ) : amountCheck === "false" ?(
        <P className={"showP"}>please fill this field</P>
      ): addressCheck === "false" ?(
        <P className={"showP"}>please fill this field</P>
      ): amountStatus === "false" ? (
        <P className={"showP"}>Amount not valid</P>
      ) : addressStatus === "false" ? (
        <P className={"showP"}>wallet address not valid</P>
      ): (
        <></>
      )}
    </form>
  );
}

export default InputForm;
