import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import Accordian from "./accordian";
import { SectionHeading } from "../../../Styles/style";

const ContinerWraper = styled(Container)`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  z-index: 0;
  padding: 100px 0 !important;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;



const FAQs = () => {
  return (
    <>
    <ContinerWraper maxWidth="xxl" id="faq">
      <Container maxWidth='lg'>
        <Grid container>
          <Grid p='20px 0px' item xs={12} >
            <SectionHeading  ta='center'>
              FAQ
            </SectionHeading>
          </Grid>
          <Grid item xs={12}>
            <Accordian />
          </Grid>
        </Grid>
      </Container>
    </ContinerWraper>
    
    </>
  );
};

export default FAQs;
