import React, { useEffect } from "react";
import { PageWrapper } from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "@emotion/styled";
import leftBg from "../../../assets/images/leftBg.png";
import rightBg from "../../../assets/images/rightBg.png";
import HeroSection from "../../Components/Sections/S1-Hero";
import MissionSection from "../../Components/Sections/S2-Mission";
import VisacardSection from "../../Components/Sections/S3-VisaCardSection";
import MixerSection from "../../Components/Sections/S4-Mixer";
import Tokenomics from "../../Components/Sections/S5-Tokenomics";
import RoadMap from "../../Components/Sections/S6-Roadmap";
import FAQs from "../../Components/Sections/S7-Faq";
import Last from "../../Components/Sections/S8-Last";

const SectionWraper = styled.div`
  width: 100%;
  position: relative;
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};

  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "100%")};
    top: ${(props) => (props.t599 ? props.t599 : props.t599)};
    left: ${(props) => (props.l599 ? props.l599 : props.l599)};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;
const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>
      <HeroSection />
      <MissionSection/>
      <VisacardSection/>
      <MixerSection/>
      <Tokenomics/>
      <SectionWraper>
        <AbsoluiteImage
          mw="20%"
          src={rightBg}
          style={{
            right: "0",
            top: "0",
            // filter: "blur(3px)",
            // opacity: ".5",
          }}
        />
        <AbsoluiteImage
          mw="20%"
          src={leftBg}
          style={{
            left: "0",
            top: "40%",
            // filter: "blur(3px)",
            // opacity: ".5",
          }}
        />
        <RoadMap />
        <FAQs />
      </SectionWraper>
      <Last />
    </PageWrapper>
  );
};

export default MainPage;
