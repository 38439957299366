import React from "react";
import {
  DataImage,
  FlexGrid,
  Paragraph,
  SectionHeading,
} from "../../../Styles/style";
import { Container, Divider, Grid } from "@mui/material";
import styled from "@emotion/styled";
import Logo from "../../../../assets/images/logo.png";
import { MenuLink } from "../../LockerMenu/styles";
import Twitter from "../../../../assets/images/twitter.png";
import Telegram from "../../../../assets/images/telegram.png";
import footerBg from "../../../../assets/images/footerBg.png";

const MainDiv = styled.div`
  background-color: #000000;
  background-image: url(${footerBg});
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;

  padding: 80px 0px;
`;
const Last = () => {
  return (
    <>
      <Divider
        sx={{
          maxWidth: "100%",
          height: "1.5px",
          background:
            "linear-gradient(45deg,#9945ff,#9945ff,#8752f3,#5497d5,#43b4ca,#28e0b9,#19fb9b,#19fb9b)",
        }}
      />
      <MainDiv>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={6} md={8} p="0px 0px">
              <DataImage mw="200px" src={Logo} />
              <Paragraph mw="350px" p="20px 0px">
                AnonSol is a tax-free Solana project that anonymous debit cards
                and anonymous token transactions.
              </Paragraph>
            </Grid>
            <FlexGrid p="30px 0 0" fd="column" item xs={6} sm={3} md={2}>
              <SectionHeading
                fs="18px"
                fs599="16px"
                ta="left"
                variant="h6"
                p="0px 0px 10px"
              >
                Quick Links
              </SectionHeading>
              <MenuLink
                ff="micker-medium"
                p="5px 0px 0px"
                href="#home"
                bd="none"
              >
                Home
              </MenuLink>
              <MenuLink
                ff="micker-medium"
                p="5px 0px 0px"
                href="#mission"
                bd="none"
              >
                Mission
              </MenuLink>
              <MenuLink
                ff="micker-medium"
                p="5px 0px 0px"
                href="#tokenomics"
                bd="none"
              >
                Token Details
              </MenuLink>
              <MenuLink
                ff="micker-medium"
                p="5px 0px 0px"
                href="#roadmap"
                bd="none"
              >
                Roadmap
              </MenuLink>
              <MenuLink
                ff="micker-medium"
                p="5px 0px 0px"
                href="#faq"
                bd="none"
              >
                Faq
              </MenuLink>
            </FlexGrid>
            <FlexGrid p="30px 0 0" fd="column" item xs={6} sm={3} md={2}>
              <SectionHeading
                fs="18px"
                fs599="16px"
                ta="left"
                variant="h6"
                p="0px 0px 10px"
              >
                Join us
              </SectionHeading>
              <div style={{ display: "flex", gap: "20px" }}>
                <MenuLink
                 disp="grid"
                  sx={{
                    border: "1px solid #fff !important",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "5px",
                  }}
                  p="4px"
                  hbgc="#9747FD"
                  target="blank"
                  href="https://twitter.com/anonsoltoken"
                >
                  <DataImage mw="20px" src={Twitter} />
                </MenuLink>
                <MenuLink
                 disp="grid"
                  sx={{
                    border: "1px solid #fff !important",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "5px",
                  }}
                  p="4px"
                  hbgc="#9747FD"
                  target="blank"
                  href="https://t.me/anonsoltoken"
                >
                  <DataImage mw="20px" src={Telegram} />
                </MenuLink>
              </div>
            </FlexGrid>
            {/* <Grid item xs={12} sx={{ borderTop: '1px solid white', padding: '20px 0px 0px' }}>
                        <Paragraph ta='center'>
                        ©2023 ANONSOL
                        </Paragraph>
                    </Grid> */}
          </Grid>
        </Container>
      </MainDiv>
    </>
  );
};
export default Last;
