import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

const BoxStyle = styled(Box)`
    /* background: ${props => props.backbg? props.backbg : 'linear-gradient( to top, #000000, #1C1C1C )'}; */
    min-height: ${props => props.minh? props.minh : '120px'};
    background:  ${props => props.backbg? props.backbg : 'linear-gradient(to bottom,#1B1622,#000000)'};
    max-width: ${props => props.mw? props.mw : '250px'} ;
    border-radius: 20px 20px;
    padding: ${props => props.pd? props.pd : '20px'};
    margin: 20px auto;
    display: grid;
    place-items: center;
`
const Para = styled(Typography)`
    /* font-family: poppins-regular; */
    color: white;
    text-align: ${props => props.ta? props.ta : 'center'};
    font-size: 14px;
    padding: 10px 0px;
    /* text-align: center; */
    margin: ${props => props.m? props.m : ''};
`
const Para1 = styled(Typography)`
    /* font-family: poppins-semibold; */
    color: white;
    font-size: 18px;
    text-align: ${props => props.ta? props.ta : 'center'};
    background: ${props => props.bg? props.bg : ''} ;
    border-radius: 10px;
    /* text-align: center; */
    padding: ${props => props.p? props.p : ''};
`
const Disc  = (props)=> <Para textAlign={props.ta}>{props.detail}</Para>
const FeatureCard = (props) => {
    const{tag, image, data} = props;
    return(
        <BoxStyle xs={12} md={4} {...props} data-aos="zoom-in-up" data-aos-duration = '1000'>
            <Para1 variant='h6' {...props}>{tag}</Para1>
            <div>
            {data?.map((val, ind)=> {
                return <Disc {...props} key={ind} detail={val[0]}/>
            })}
            </div>
        </BoxStyle>
    )
}

export default FeatureCard;