import { Container } from '@mui/material';
import React from 'react'
import DesktopMenu from './component/DesktopMenu';
import MobileMenu from './component/MobileMenu';

const LockerMenu = (props) => {
    const menuList = [ // You can pass 4 perameters for menu title, link, target and customClass
        {
        title: "Home",
        link: "#home",
        },
        {
        title: "Misson",
        link: "#mission",
        },
        {
        title: "Tokenomics",
        link: "#tokenomics",
        },
        {
        title: "Roadmap",
        link: "#roadmap",
        },
        {
        title: "FAQ",
        link: "#faq",
        },
    ];
  return (
    <Container sx={{position:"relative",zIndex:"1"}}>
        <DesktopMenu menuList={menuList} />
        <MobileMenu menuList={menuList} />
    </Container>
  )
}

export default LockerMenu