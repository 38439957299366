import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import {
  AbsoluiteBackgroundVideo,
  CustomGrid,
  DataImage,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import VideoBg from "../../../../assets/images/heroVideo.mp4";
import title from "../../../../assets/images/title.png";
import LockerMenu from "../../LockerMenu";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100dvh - 79px);
  width: 100%;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 79px);
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  align-items: end;
  @media (max-width: 599px) {
  }
`;

const HeroSection = () => {
  return (
    <>
      <SectionWraper id="home">
        <LockerMenu />
        <AbsoluiteBackgroundVideo src={VideoBg} muted autoPlay loop />
        <InnerContinerWraper>
          <CustomGrid container jc="center">
            <CustomGrid
              item
              xs={12}
              sm={9}
              md={6}
              fd="column"
              ai="center"
              g="10px"
            >
              <DataImage mw="50%" mw599="100%" src={title} />
              <Paragraph
                fs="24px"
                fs599="14px"
                lh="1.5"
                sx={{ margin: { xs: "0", sm: "0 20px" } }}
              >
                Shaping Shadows In The Crypto Realm.
                <br /> Mix, Spend, Stay Anonymous.
              </Paragraph>
              <CustomButton
                href="https://anonsol.gitbook.io/anonsol-whitepaper/"
                target="blank"
              >
                Explore More
              </CustomButton>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default HeroSection;
