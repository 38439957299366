import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

const CustomBtn = styled(Button)`
  background: ${(props) => (props.bgc ? props.bgc : "transparent")};
  font-size: ${(props) => (props.fs ? props.fs : "16px")};
  border-radius: ${(props) => (props.br ? props.br : "5px")};
  min-width: ${(props) => (props.w ? props.w : "130px")};
  font-family: ${(props) => (props.fm ? props.fm : "PoppinsM")};
  margin: ${(props) => (props.m ? props.m : "0")};
  padding: ${(props) => (props.p ? props.p : "4px 10px")};
  color: ${(props) => (props.c ? props.c : "#fff")};
  border: ${(props) => (props.b ? props.b : "1px solid #fff")};
  transition: .5s;
  text-transform: none;
  cursor: ${(props) => (props.cur ? props.cur : "pointer")};
  z-index: 1;
  :hover {
    color: ${(props) => (props.hc ? props.hc : "#000000")};
    background: ${(props) => (props.hbgc ? props.hbgc : "#ffffff")};
    border-color: ${(props) => (props.brc ? props.brc : "#fff")};
  }
  @media (max-width: 599px) {
    padding: ${(props) => (props.p ? props.p : "8px 20px")};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "14px")};
  }
`;

const CustomButton = (props) => {
  const { children } = props;
  return (
    <CustomBtn variant="filled" {...props}>
      {children}
    </CustomBtn>
  );
};

export default CustomButton;
