import React from "react";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import visaCardBg from "../../../../assets/images/visaCardImage.png";

import X from "../../../../assets/images/X.png";
import tg from "../../../../assets/images/tg.png";
import amazon from "../../../../assets/images/amazon.png";
import facebook from "../../../../assets/images/facebook.png";
import walmart from "../../../../assets/images/walmart.png";
import nameCheap from "../../../../assets/images/nameCheap.png";
import aliexpress from "../../../../assets/images/aliexpress.png";
import netflix from "../../../../assets/images/netflix.png";
import shadow from "../../../../assets/images/shadow.png";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import Marquee from "react-fast-marquee";
const SectionWraper = styled.div`
  overflow: hidden;
  padding-bottom: 50px;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(40vh - 0px);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const StyledMarque = styled(Marquee)`
  background-color: transparent;
  position: relative;
  z-index: 0;
  ::after {
    content: "";
    /* background: linear-gradient(to right, #000 10%, transparent, #000); */
    width: calc(100%);
    height: calc(100%);
    filter: blur(10px);
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 1;
  }
  @media (max-width: 599px) {
  }
`;

const VisaCardGrid = styled(CustomGrid)`
  background-image: url(${visaCardBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  min-height: 200px;
`;
const SectionHeadingPower = styled.sup`
  /* white-space: nowrap; */
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: ${(props) => (props.fs ? props.fs : "50px")};
  font-family: ${(props) => (props.fm ? props.fm : "PoppinsSB")};
  line-height: 1;
  /* letter-spacing: 3px; */
  position: relative;
  z-index: 9;
  color: ${(props) => (props.c ? props.c : "#fff")};
  @media (max-width: 1175px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs1175 : "46px")};
  }
  @media (max-width: 1024px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs1024 : "36px")};
  }
  @media (max-width: 599px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "24px")};
  }
`;
const OuterDiv = styled.div`
  position: relative;
  /* @media (max-width: 599px) {

  } */
`;
const VisacardSection = () => {
  return (
    <>
      <SectionWraper id="home">
        <InnerContinerWraper>
          <CustomGrid container g="20px 0" jc="space-between" ai="none">
            <CustomGrid item xs={12} jc="start" p="10px 0">
              <SectionHeading>Visa Card</SectionHeading>
            </CustomGrid>
            <VisaCardGrid
              p="10px"
              item
              xs={12}
              sm={5.9}
              fd="column"
              ai="start"
              g="20px"
              sx={{ borderRadius: "14px", minHeight: "200px" }}
            ></VisaCardGrid>
            <CustomGrid
              p="60px"
              item
              xs={12}
              sm={5.9}
              fd="column"
              ai="start"
              jc="start"
              g="10px"
              sx={{ borderRadius: "14px", background: "#9747FD" }}
            >
              <SectionHeading fs="60px" ta="left" c="#19FA9C">
                Promo
              </SectionHeading>
              <SectionHeading
                ta="left"
                fs="120px"
                fs1175="100px"
                fs1024="70px"
                fs599="40px"
              >
                50% <SectionHeadingPower>Off</SectionHeadingPower>
              </SectionHeading>
              <Paragraph textAlign="left" fs="14px" fs599="12px">
                *on card fee for holders of more than 0.2% supply
              </Paragraph>
            </CustomGrid>
            <CustomGrid
              p="30px 20px"
              item
              xs={12}
              sm={5.9}
              fd="column"
              ai="start"
              g="20px"
              sx={{
                borderRadius: "14px",
                background: "linear-gradient(to bottom,#1B1622,#000000)",
              }}
            >
              <Paragraph textAlign="left">
                Unlock financial freedom with AnonSol's Virtual and Physical
                Visa Cards. Seamlessly blending cutting-edge technology and
                privacy, our cards empower you to transact securely and
                confidentially. Experience a new era of financial autonomy –
                where every transaction is a step towards a private and
                empowered future.
              </Paragraph>
              {/* href="/visa-card"  */}
              <CustomButton  hbgc="#9747FD" brc="#9747FD" hc="#fff">
                Buy Card
              </CustomButton>
            </CustomGrid>
            <CustomGrid
              p="30px 20px"
              item
              xs={12}
              sm={5.9}
              fd="column"
              ai="start"
              jc="start"
              g="10px"
              sx={{
                borderRadius: "14px",
                background: "linear-gradient(to bottom,#1B1622,#000000)",
              }}
            >
              <Paragraph
                textAlign="left"
                fs="22px"
                fs599="20px"
                fontFamily="PoppinsM"
              >
                Use Anywhere
              </Paragraph>
              <OuterDiv>
                <Box sx={{width:"40px",filter:"blur(6px)",height:{xs:"60px",sm:"60px"},zIndex:"2",background: "linear-gradient(to bottom,#100d14,#0e0b11)",position:"absolute",top:{xs:"-10px",sm:"0"},left:"-14px"}} alt=""/> 
                <Box sx={{width:"40px",filter:"blur(6px)",height:{xs:"60px",sm:"60px"},zIndex:"2",background: "linear-gradient(to bottom,#100d14,#0e0b11)",position:"absolute",top:{xs:"-10px",sm:"0"},right:"-14px"}} alt=""/> 
                <StyledMarque>
                  <DataImage m="10px 0 0" src={tg} mw="90%" />
                  <DataImage m="10px 0 0" src={X} mw="90%" />
                  <DataImage m="10px 0 0" src={amazon} mw="90%" />
                  <DataImage m="10px 0 0" src={walmart} mw="90%" />
                  <DataImage m="10px 0 0" src={facebook} mw="90%" />
                  <DataImage m="10px 0 0" src={nameCheap} mw="90%" />
                  <DataImage m="10px 0 0" src={aliexpress} mw="90%" />
                  <DataImage m="10px 0 0" src={netflix} mw="90%" />
                </StyledMarque>
              </OuterDiv>
              <OuterDiv>
              <Box sx={{width:"40px",filter:"blur(6px)",height:{xs:"60px",sm:"60px"},zIndex:"2",background: "linear-gradient(to bottom,#0e0b11,#070608)",position:"absolute",top:{xs:"-10px",sm:"0"},left:"-14px"}} alt=""/> 
                <Box sx={{width:"40px",filter:"blur(6px)",height:{xs:"60px",sm:"60px"},zIndex:"2",background: "linear-gradient(to bottom,#0e0b11,#070608)",position:"absolute",top:{xs:"-10px",sm:"0"},right:"-14px"}} alt=""/>
                <StyledMarque>
                  <DataImage m="10px 0 0" src={netflix} mw="90%" />
                  <DataImage m="10px 0 0" src={facebook} mw="90%" />
                  <DataImage m="10px 0 0" src={nameCheap} mw="90%" />
                  <DataImage m="10px 0 0" src={amazon} mw="90%" />
                  <DataImage m="10px 0 0" src={walmart} mw="90%" />
                  <DataImage m="10px 0 0" src={aliexpress} mw="90%" />
                  <DataImage m="10px 0 0" src={tg} mw="90%" />
                  <DataImage m="10px 0 0" src={X} mw="90%" />
                </StyledMarque>
              </OuterDiv>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default VisacardSection;
