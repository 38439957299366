import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Logo from "../../../../assets/images/logo.png";
import { DataImage } from "../../../Styles/style";
import Twitter from "../../../../assets/images/twitter.png";
import Telegram from "../../../../assets/images/telegram.png";
import {
  MenuLink,
  MobileMainMenu,
  DrawerBox,
  DrawerDivider,
  Humburgger,
  HumburggerClose,
  MenuButton,
} from "../styles";

const MobileMenu = (props) => {
  const [state, setState] = useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("thest: ", anchor + " : " + open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  return (
    <MobileMainMenu>
      <MenuLink href="/" p="15px 0 15px">
        <DataImage src={Logo} mw="140px" />
      </MenuLink>
      <MenuButton onClick={toggleDrawer("left", true)}>
        {state["left"] ? (
          <HumburggerClose>Close</HumburggerClose>
        ) : (
          <Humburgger></Humburgger>
        )}
      </MenuButton>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <DrawerBox
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <DrawerDivider>
            <MenuLink href="/">
              <DataImage src={Logo} mw="140px" />
            </MenuLink>
            <Divider
              sx={{ background: "#19FA9C", height: "2px", width: "100%" }}
            />
            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {props.menuList.map((value, i) => (
                <MenuLink
                  key={i}
                  href={value.link}
                  target={value.target}
                  className={value.customClass + " d-block"}
                >
                  {value.title}
                </MenuLink>
              ))}
              <div style={{ display: "flex",gap:"20px", justifyContent: "center" , alignItems: 'center' }}>
                <MenuLink
                disp="grid"
                  sx={{
                    border: "1px solid #fff !important",
                    display: "grid !important",
                    placeItems: "center",
                    borderRadius: "5px",
                  }}
                  p="4px"
                  hbgc="#19FA9C"
                  target="blank"
                  href="https://twitter.com/anonsoltoken"
                >
                  <DataImage mw="20px" src={Twitter} />
                </MenuLink>
                <MenuLink
                disp="grid"
                  sx={{
                    border: "1px solid #fff !important",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "5px",
                  }}
                  p="4px"
                  hbgc="#19FA9C"
                  target="blank"
                  href="https://t.me/anonsoltoken"
                >
                  <DataImage mw="20px" src={Telegram} />
                </MenuLink>
              </div>
            </List>
          </DrawerDivider>
          <DrawerDivider></DrawerDivider>
        </DrawerBox>
      </Drawer>
    </MobileMainMenu>
  );
};

export default MobileMenu;
