import React from "react";
import { DesktopMainMenu,  MenuLink } from "../styles";
import Logo from "../../../../assets/images/logo.png"
import { DataImage } from "../../../Styles/style";
import Twitter from '../../../../assets/images/twitter.png';
import Telegram from '../../../../assets/images/telegram.png';

const DesktopMenu = (props) => {
 
  return (
    <DesktopMainMenu maxWidth="xl">
      <MenuLink href="/" p="0px">
        <DataImage src={Logo} mw='160px'/>
      </MenuLink>
      <div style={{display: 'flex', alignItems: 'center' }}>
        {props.menuList.map((value, i) => <MenuLink key={i} href={value.link} target={value.target} className={value.customClass}>{value.title}</MenuLink> )}
        <div style={{display: 'flex',gap:"20px", alignItems: 'center' }}>
        <MenuLink sx={{border:"1px solid #fff !important", display:"grid",placeItems:"center",borderRadius:"5px"}} p='4px' hbgc="#19FA9C" target="blank"  href='https://twitter.com/anonsoltoken'>
          <DataImage mw='20px' src={Twitter}/>
        </MenuLink>
        <MenuLink sx={{border:"1px solid #fff !important", display:"grid",placeItems:"center",borderRadius:"5px"}} p='4px' hbgc="#19FA9C" target="blank"  href='https://t.me/anonsoltoken'>
          <DataImage mw='20px' src={Telegram}/>
        </MenuLink>
      </div>
      </div>
     
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
