import React, { useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { AbsoluiteBackgroundVideo, FlexGrid, Paragraph, SectionHeading } from "../../../Styles/style";
import FeatureCard from "../../cards";
import VideoBg from "../../../../assets/images/coinflip.mp4";
import styled from "@emotion/styled";
import CustomButton from "../../CustomBtn";

const GridStyle = styled(Grid)`
  display: flex;
  align-items: end;
  justify-content: center;
  min-height: 700px;
  background-color: rgb(0, 0, 0);
  padding: 20px 30px;
  border-radius: 10px;
  @media (max-width: 899px) {
    min-height: auto;
  }
  @media (max-width: 599px) {
    padding: 20px 10px;
  }
`;
const MainDiv = styled(Container)`
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  position: relative;
  display: grid;
  place-items: center;
`;

const AbsoluiteVideo = styled(AbsoluiteBackgroundVideo)`
  width: 80%;
  min-height: 80%;
  position: absolute;
  bottom: 10%;
  right: 50%;
  transform: translate(50%);
  /* @media (min-width: 1499px) {
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 0;
  } */
  @media (max-width: 899px) {
    width: 100%;
    min-height: 40%;
    top: 35%;
    /* bottom: 0; */
  }
`;
const Tokenomics = () => {
  const [showToast, setShowToast] = useState(false);
  const addressToCopy = "r89LZwYf7ZiWVSk8uR6yEboWDEn52EgeXxMJg7UtX7w";
  const handleCopy = () => {
    navigator.clipboard.writeText(addressToCopy);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };
  return (
    <MainDiv maxWidth="lg" id="tokenomics">
              <AbsoluiteVideo src={VideoBg} muted autoPlay loop />
      <Grid container >
        <Grid item xs={12}>
          <SectionHeading ta="center" variant="h2" p="30px 0px">
            Tokenomics
          </SectionHeading>
          {/* <Paragraph ta="center" p="20px 0px">
            Here's a glimpse into our tokenomics.{" "}
          </Paragraph> */}
        </Grid>
        <Grid item xs={12} md={3} order={{ xs: 2, sm: 2, md: 1 }}>
          <FeatureCard tag="AnonSol" data={[["Name"]]} />
          <div style={{ paddingTop: "30px" }}>
            <FeatureCard tag="$ANONSL" data={[["SYMBOL"]]} />
          </div>
          <div style={{ paddingTop: "30px" }}>
            <FeatureCard tag="Solana" data={[["Network"]]} />
          </div>
        </Grid>
        <GridStyle item xs={12} sm={12} md={6} order={{ xs: 1, sm: 1, md: 2 }} sx={{justifyContent:"center"}}>
          {/* Placeholder content with assumed components */}
          <Box>
            <SectionHeading ta="center" fs="24px" p="10px 0px">
              Contract Address
            </SectionHeading>
            <Paragraph ta="center" fs599="11px">
            {addressToCopy}
            </Paragraph>
            {/* <Paragraph
              ta="left"
              p="10px 0px 15px"
              // s599='10px'
            >
              In our pursuit, we position ourselves as a catalyst, propelling
              financial empowerment and privacy to the forefront. AnonSol
              envisions a future where individuals, regardless of their
              background, confidently participate in shaping the next chapter of
              finance, marking a paradigm shift towards inclusivity and
              widespread financial autonomy.
            </Paragraph>
            <Paragraph ta="center" p="20px 0px">
              This commitment to decentralization and safety is at the core of
              the $ANONSOL project.{" "}
            </Paragraph> */}
            {/* <BDiv>
              <AllImage maxw="335px" src={Feature5} />
            </BDiv> */}

            <FlexGrid
              item
              xs={12}
              jc="center"
              ai="center"
              fd="column"
              p="10px 0px"
            >
              <CustomButton onClick={handleCopy}>{showToast ? "Copied" : "Copy" }</CustomButton>
              </FlexGrid>
          </Box>
        </GridStyle>
        <Grid item xs={12} md={3} order={{ xs: 3, sm: 3, md: 3 }}>
          <FeatureCard tag="No Tax" data={[["Tax"]]} />
          <div style={{ paddingTop: "20px" }}>
            <FeatureCard tag="Liquidity" data={[["LP Burnt"]]} />
          </div>
          <div style={{ paddingTop: "20px" }}>
            <FeatureCard tag="Contract" data={[["RENOUNCED"]]} />
          </div>
        </Grid>
      </Grid>
    </MainDiv>
  );
};

export default Tokenomics;
