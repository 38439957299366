import React, { useState } from "react";
import PermanentDrawerLeft from "../../Components/SideBar";
import {
  CustomGrid,
  DataImage,
  Paragraph,
  SectionHeading,
  VIDEO,
} from "../../Styles/style";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import BgVideo from '../../../assets/images/headervideo.mp4';
import styled from "@emotion/styled";
import cardImage from "../../../assets/images/visaCardBg.png";
import logo from "../../../assets/images/logo.png";
import chip from "../../../assets/images/chip.png";
import visaIcon from "../../../assets/images/visaIcon.png";
import CustomButton from "../../Components/CustomBtn";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const CustomRadio = styled(Radio)`
  color: #fff;
  &.Mui-checked {
    color: #19fb9b;
  }
`;
const CustomFormControlLabel = styled(FormControlLabel)`
  color: #ffffff;
`;
const StatusDiv = styled.div`
  min-width: 170px;
  border: ${(props) =>
    props.Status === "Live"
      ? "1px solid #19fb9b"
      : props.Status === "Pending"
      ? "1px solid #9945FF"
      : "1px solid #8f8f8f"};
  background-color: ${(props) =>
    props.Status === "Live"
      ? "#19fb9d2d"
      : props.Status === "Pending"
      ? "#9945ff2d"
      : "#383838"};
  padding: 0 10px;
  border-radius: 20px;
  margin: 40px 0 20px;
`;
const Card = styled(CustomGrid)`
  background-image: ${(props) =>
      props.Status === "Live"
        ? "linear-gradient( to bottom, #19fb9d78 0%,transparent 40%, #8e40ed9e 100%)"
        : "linear-gradient( to bottom, #1d1d1df2 0%,transparent 40%, #363636 100%)"},
    url(${cardImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 400px;
  height: 250px;
  padding: 20px;
  border-radius: 20px;
  @media(max-width: 899px){
    width: 100%;
  }
`;

export const StyledSectionHeading = styled(SectionHeading)`
  /* white-space: nowrap; */
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: ${(props) => (props.fs ? props.fs : "30px")};
  font-family: ${(props) => (props.fm ? props.fm : "PoppinsSB")};
  line-height: 1;
  /* letter-spacing: 3px; */
  position: relative;
  z-index: 9;
  color: ${(props) => (props.c ? props.c : "#fff")};
  @media (max-width: 1175px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs1175 : "36px")};
  }
  @media (max-width: 1024px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs1024 : "34px")};
  }
  @media (max-width: 599px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "32px")};
  }
`;
const VisaCard = () => {
  const [Status, setStatus] = useState("None");
  const [OrderID, setOrderID] = useState("*****");
  const [Name, setName] = useState("*****");
  const [Balance, setBalance] = useState("*****");
  const [CardNumber, setCardNumber] = useState("****   ****   ****");
  const [ExpiryDate, setExpiryDate] = useState("*****");
  const [CVV, setCVV] = useState("*****");

  // setOrderID("VREU79");
  // setName("Anon38");
  // setBalance("$1.38");
  // setCardNumber("8523 5412 0102 9121");
  // setExpiryDate("12/26");
  // setCVV("254");
  const handelDeposit = (value) => {
    setStatus("Pending");
    setCardNumber("");
    setTimeout(() => {
      setStatus("Live");
      setOrderID("VREU79");
      setName("Anon38");
      setBalance("$1.38");
      setCardNumber("8523 5412 0102 9121");
      setExpiryDate("12/26");
      setCVV("254");
    }, 4000);
  };

  return (
    <>
    <VIDEO muted loop autoPlay>
      <source src={BgVideo} />
    </VIDEO>
      <PermanentDrawerLeft>
        <CustomGrid container jc="end" p="0px 20px">
          <CustomGrid contaienr item xs={12} md={8} jc="space-between" g="10px" 
              fd899="column-reverse">
            <CustomGrid
              contaienr
              item
              xs={12}
              sm={10}
              md={5}
              ai="center"
              fd="column"
            >
              <StyledSectionHeading variant="h4" ta="center">
                My Cards
              </StyledSectionHeading>
              <StatusDiv Status={Status}>
                <Paragraph ta="center" fs="18px" c={
                        Status === "Live"
                          ? "#19fb9b"
                          : Status === "Pending"
                          ? "#9945FF"
                          : "#ffffff"
                      }>
                  Status: {Status}
                </Paragraph>
              </StatusDiv>
              <Card contaienr Status={Status} ai="space-between" fd="column">
                <CustomGrid item xs={12} jc="space-between">
                  <DataImage src={logo} mw="140px" />
                  <Paragraph fs="14px">DEBIT</Paragraph>
                </CustomGrid>
                <CustomGrid item xs={12} jc="start" p="0 20px">
                  <DataImage src={chip} mw="45px" />
                </CustomGrid>
                <CustomGrid item xs={12} jc="center">
                  <Paragraph
                    fs="20px"
                    sx={{ letterSpacing: "7px", whiteSpace: "nowrap" }}
                  >
                    {CardNumber}
                  </Paragraph>
                </CustomGrid>
                <CustomGrid item xs={12} jc="space-between">
                  {Status === "Pending" ? (
                    <Paragraph fs="22px">IN PROGRESS...</Paragraph>
                  ) : (
                    <Paragraph fs="14px">
                      EXP {ExpiryDate} <br /> CVV {CVV}
                    </Paragraph>
                  )}

                  <DataImage src={visaIcon} mw="100px" />
                </CustomGrid>
              </Card>
              <Box>

              <Paragraph ta="left" fs="14px" p="20px 0 0 16px">
                Order ID: {OrderID} <br />
                Name: {Name} <br />
                Balance: {Balance} <br />
              </Paragraph>
              <Paragraph ta="left" fs="14px" >
               <ContentCopyIcon onClick={()=> navigator.clipboard.writeText(CardNumber)} sx={{fontSize:"14px",cursor:"pointer"}}/>Card Number: {CardNumber} <br />
              </Paragraph>
              <Paragraph ta="left" fs="14px" >
              <ContentCopyIcon onClick={()=> navigator.clipboard.writeText(ExpiryDate)} sx={{fontSize:"14px",cursor:"pointer"}}/>Expiry: {ExpiryDate} <br />
              </Paragraph>
              <Paragraph ta="left" fs="14px" >
              <ContentCopyIcon onClick={()=> navigator.clipboard.writeText(CVV)} sx={{fontSize:"14px",cursor:"pointer"}}/>CVV: {CVV} <br />
              </Paragraph>
              </Box>
            </CustomGrid>
            <CustomGrid
              contaienr
              item
              xs={12}
              md={4}
              lg={3}
              ai="center"
              fd="column"
              g="10px"
              sx={{
                background: "#ffffff11",
                backdropFilter: "blur(15px)",
                minHeight: {xs:"auto",md:"calc(100vh - 150px)"},
                borderRadius: "20px",
                padding:{xs:"20px",md:"10px"},
              }}
            >
              <StyledSectionHeading fs="26px" variant="h4" ta="center">
                Issue Card
              </StyledSectionHeading>
              <Paragraph ta="center" fs="12px" p="0 10px 20px">
                Choose Your <br /> Deposit Amount
              </Paragraph>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="$50"
                  name="radio-buttons-group"
                  sx={{flexDirection:{ xs:"row" , md:"column"}}}
                >
                  <CustomFormControlLabel
                    labelPlacement="start"
                    value="$50"
                    control={<CustomRadio />}
                    label="$50"
                  />
                  <CustomFormControlLabel
                    labelPlacement="start"
                    value="$100"
                    control={<CustomRadio />}
                    label="$100"
                  />
                  <CustomFormControlLabel
                    labelPlacement="start"
                    value="$150"
                    control={<CustomRadio />}
                    label="$150"
                  />
                  <CustomFormControlLabel
                    labelPlacement="start"
                    value="$250"
                    control={<CustomRadio />}
                    label="$250"
                  />
                  <CustomFormControlLabel
                    labelPlacement="start"
                    value="$500"
                    control={<CustomRadio />}
                    label="$500"
                  />
                </RadioGroup>
              </FormControl>
              <CustomButton
                w="100px"
                m="0"
                br="20px"
                fs="14px"
                p="0px 10px"
                b="1px solid #19fb9b"
                c="#19FB9B"
                hc="#19FB9B"
                bgc="#19fb9d2d"
                hbgc="#19fb9d78"
                onClick={() => handelDeposit()}
              >
                Deposit
              </CustomButton>
            </CustomGrid>
          </CustomGrid>
        </CustomGrid>
      </PermanentDrawerLeft>
    </>
  );
};

export default VisaCard;
