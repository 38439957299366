import styled from "@emotion/styled";
import { Container } from "@mui/material";
import React from "react";
import Mixeranim from "../../../../assets/images/Mixeranim.mp4";
import {
  CustomGrid,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: calc(100vh);
  width: 100%;
  padding:50px 0;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto; 
    padding:0px 0 50px;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;
const ColoredDiv = styled(CustomGrid)`
  min-height: 300px;
  width: 100%;
  z-index: 0;
  display: grid;
  place-items: center;
  position: relative;
  @media (max-width: 599px) {
    min-height: 150px;
  }
  ::after {
    content: "";
    background: linear-gradient(
      45deg,
      #9945ff,
      #9945ff,
      #8752f3,
      #5497d5,
      #43b4ca,
      #28e0b9,
      #19fb9b,
      #19fb9b
    );
    width: calc(100% );
    height: calc(100% );
    filter: blur(20px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
`;
const ColoredinnerDiv = styled(CustomGrid)`
  position: relative;
  overflow: hidden !important;
  min-width: 75.7vw;
  height: 300px;
  border-radius: 14px;
  @media (min-width: 1499px) {
    min-width: 45vw;
  }
  @media (max-width: 599px) {
    height: 150px;
  }
  /* @media (max-width: 599px) {

  } */
`;
const AbsoluiteVideo = styled.video`
  width: 100%;
  object-fit: fill !important;
`;
const MixerSection = () => {
  return (
    <>
      <SectionWraper id="home">
        <InnerContinerWraper>
          <CustomGrid container g="40px 0" jc="space-between" ai="none">
            <CustomGrid item xs={12} jc="start" p="40px 0">
              <SectionHeading>Mixer</SectionHeading>
            </CustomGrid>
            <ColoredDiv container item xs={12}>
              <ColoredinnerDiv item xs={12}>
                <AbsoluiteVideo src={Mixeranim} muted autoPlay loop />
              </ColoredinnerDiv>
            </ColoredDiv>
            <CustomGrid
              p="30px 25px 25px"
              item
              xs={12}
              sm={5.9}
              fd="column"
              ai="start"
              jc="start"
              g="20px"
              sx={{
                borderRadius: "14px",
                background: "linear-gradient(to bottom,#1B1622,#000000)",
              }}
            >
              <Paragraph textAlign="left">
                Dive into the future of financial privacy with AnonSol's Mixer.
                Our innovative mixing technology, currently in development,
                ensures anonymous token transfers, obfuscating funds for covert
                transactions. Redefine the way you engage with the decentralized
                realm – where privacy and security converge for a confidential
                and empowered financial experience.
              </Paragraph>
              {/* href="/mixer" */}
              <CustomButton  hbgc="#19FA9C" brc="#19FA9C">Mix Now</CustomButton>
            </CustomGrid>
            <CustomGrid
              p="30px 25px 25px"
              item
              xs={12}
              sm={5.9}
              fd="column"
              ai="start"
              jc="start"
              g="10px"
              sx={{ borderRadius: "14px", background: "#19FA9C" }}
            >
              <Paragraph
                textAlign="left"
                color="#000"
                fs="22px"
                fs599="20px"
                fontFamily="PoppinsM"
              >
                You Can Mix:
              </Paragraph>
              <Paragraph textAlign="left" color="#000">
                - SOL To ETH<br />
              </Paragraph>
              <Paragraph textAlign="left" color="#000">
                - SOL To BTC <br />
              </Paragraph>
              <Paragraph textAlign="left" color="#000">
                - SOL To USDC(SOL)
              </Paragraph>
              <Paragraph textAlign="left" color="#000">
                - SOL To ADA
              </Paragraph>
              <Paragraph textAlign="left" color="#000">
                - SOL To MATIC<br />
              </Paragraph>
              <Paragraph textAlign="left" color="#000">
                - SOL To USDT(BEP20) <br />
              </Paragraph>
              <Paragraph textAlign="left" color="#000">
                - SOL To USDT(ERC20)
              </Paragraph>
              <Paragraph textAlign="left" color="#000">
                - SOL To BNB(BEP20)
              </Paragraph>
              <Paragraph textAlign="left" fs="14px" fs599="12px" color="#000">
                More pairs are coming...
              </Paragraph>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default MixerSection;
