import styled from "@emotion/styled";
import { FormControl, Grid, Typography } from "@mui/material";

const PageWrapper = styled.div`
  background-color: #000;
  position: relative;
  overflow: hidden;
  /* overflow: hidden !important; */
`;
const DataImage = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  z-index: 1;
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 )};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599)};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => props.t};
  left: ${(props) => props.l};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "100%")};
    top: ${(props) => (props.t599 ? props.t599 : props.t599)};
    left: ${(props) => (props.l599 ? props.l599 : props.l599)};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;
const AbsoluiteBackgroundVideo = styled.video`
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  position: absolute;
  bottom: 0%;
  right: 0%;
  z-index: 0;
`;
const SectionHeading = styled(Typography)`
  /* white-space: nowrap; */
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: ${(props) => (props.fs ? props.fs : "50px")};
  font-family: ${(props) => (props.fm ? props.fm : "PoppinsSB")};
  line-height: 1;
  /* letter-spacing: 3px; */
  position: relative;
  z-index: 9;
  color: ${(props) => (props.c ? props.c : "#fff")};
  @media (max-width: 1175px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs1175 : "46px")};
  }
  @media (max-width: 1024px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs1024 : "36px")};
  }
  @media (max-width: 599px) {
    text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "24px")};
  }
`;

const CustomGrid = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  @media (max-width: 899px) {
    flex-direction: ${(props) => props.fd899};
  }
`;
const ColumnGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
`;
const StyledSpan = styled.span`
  color: #ff1036;
  text-decoration: line-through;
  text-decoration-color: #ff1036;
`;
const Paragraph = styled(Typography)`
  text-align: ${(props) => (props.ta ? props.ta : "")};
  color: ${(props) => (props.c ? props.c : "#FFFFFF")};
  padding: ${(props) => (props.p ? props.p : "")};
  max-width: ${(props) => (props.mw ? props.mw : "")};
  /* font-family: micker-medium; */
  margin: ${(props) => (props.m ? props.m : "")};
  font-size: ${(props) => (props.fs ? props.fs : "14px")};
  /* line-height: ${(props) => (props.lh ? props.lh : "1")}; */
  letter-spacing: ${(props) => (props.ls ? props.ls : "")};
  z-index: 1;
  position: relative;
  @media (max-width: 599px) {
    font-size: ${(props) => (props.fs599 ? props.fs599 : "14px")};
    /* text-align: center; */
  }
`;
const FlexGrid = styled(Grid)`
  display: flex;
  justify-content: ${(props) => (props.jc ? props.jc : "")};
  align-items: ${(props) => (props.ai ? props.ai : "")};
  margin: ${(props) => (props.m ? props.m : "")};
  padding: ${(props) => (props.p ? props.p : "")};
  flex-direction: ${(props) => (props.fd ? props.fd : "")};
  position: relative;
  @media (max-width: 599px) {
    justify-content: ${(props) => (props.jc599 ? props.jc599 : "")};
    align-items: ${(props) => (props.ai599 ? props.ai599 : "")};
    flex-direction: ${(props) => (props.fd599 ? props.fd599 : "")};
  }
`;
export const VIDEO = styled.video`
width: 100%;
height: 100%;
object-fit: cover;
position: fixed;
top: 0%;
left: 0%;
z-index: -1;
`;
const Form = styled(FormControl)`
  width: 100%;
  border: 1px solid #19fb9b;
  border-radius: 20px !important;
  padding: 1.45px 10px;
  color: #fff;
  :focus {
    outline: 1px solid #19fb9b;
  }

  & > .MuiInputBase-root {
    color: #fff;
    padding:0;
    & > .MuiNativeSelect-select {
      width: calc(100% );
      font-size: 14px;
      &::selection{
            background-color: #fff;
        }
      & > option {
        background-color: #19fb9b;
        backdrop-filter: blur(10px);
        color:#000 !important;
        :hover{
          background-color: #19fb9b;
        }
        
      }
    }
    ::before {
      border-bottom: none !important;
    }
    ::after {
      border-bottom: none !important;
    }
  }
  @media (max-width: 599px) {
    width: 100%;
  }
`;
const InputField = styled.input`
  border: ${(props) => (props.b ? props.b: "1px solid #19fb9b")};
  border-radius: 20px;
  padding: 9px 10px;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  width: ${(props) => (props.w ? props.w : "100%")};
  :focus {
    outline: 1px solid var(--primary-color);
  }
  @media (max-width: 599px) {
    flex-direction: ${(props) => (props.fd ? props.fd : "row")};
    align-items: flex-start;
    width: ${(props) => props.w599};
  }
`;
export {
  PageWrapper,
  FlexGrid,
  DataImage,Paragraph,
  AbsoluiteImage,
  SectionHeading,
  CustomGrid,
  ColumnGrid,
  AbsoluiteBackgroundVideo,
  StyledSpan,Form,InputField
};
