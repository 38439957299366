import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import cardTitle from "../../../assets/images/cardTitle.png";
import disableCardTitle from "../../../assets/images/disableCardTitle.png";
import mixerTitle from "../../../assets/images/mixerTitle.png";
import disableMixerTitle from "../../../assets/images/disableMixerTitle.png";
import logoMixer from "../../../assets/images/logoMixer.png";
import { DataImage } from "../../Styles/style";
import { Link } from "@mui/material";


const drawerWidth = 150;

const PermanentDrawerLeft = ({ children }) => {
    
  const [pathname, setPathname] = React.useState('');

  React.useEffect(() => {
    const currentUrl = window.location.href;

    const url = new URL(currentUrl);
    const path = url.pathname;
    setPathname(path);
  }, []); 
  return (
    <Box sx={{ display: "flex"  }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width:{xs:"100%",md:  `calc(100% - ${drawerWidth}px)`},
          ml:{xs:"0px",md: `${drawerWidth}px`},
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: "transparent",
            borderBottom: "1px solid #fff",
          }}
        >
          <Typography variant="body1" noWrap component="div">
          Anonsol Price: $****
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: {xs:"0",md:drawerWidth},
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: {xs:"100%",md:drawerWidth},
            height: {xs:"120px",md:"100%"},
            boxSizing: "border-box",
            backgroundColor: "#000",
            top:{xs:"auto",md:"0"},
            bottom:{xs:"0",md:"none"},
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List
          sx={{
            display: "flex",
            flexDirection: {xs:"row",md:"column"},
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link href="/" padding={2} sx={{display:{xs:"none",md:"block"}}}>

          <DataImage mw="70px" p="30px" src={logoMixer} />
          </Link>
          <ListItem 
            key={"1"}
            disablePadding
            sx={{
              display: "grid",
              placeItems: "center",
              justifyContent: "center",
              padding: {xs:"0px",md:"20px 0"},
            }}
          >
            <ListItemButton href="/visa-card">
              <ListItemIcon>
                <DataImage mw="55px" src={ pathname === "/visa-card" ? cardTitle : disableCardTitle} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          <Divider
            sx={{ width:{xs:"1px",md:"100%"}, height: {xs:"100%",md:"1px"}, backgroundColor: "#fff" }}
          />
          <ListItem
            key={"2"}
            disablePadding
            sx={{
              display: "grid",
              placeItems: "center",
              justifyContent: "center",
              padding: {xs:"0px",md:"20px 0"},
            }}
          >
            <ListItemButton  href="/mixer">
              <ListItemIcon sx={{display:"flex",justifyContent:"center"}}>
                <DataImage mw="40px" src={pathname === "/mixer" ? mixerTitle : disableMixerTitle} />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: "transparent", p: {xs:"20px 0 130px 0",md:5} }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default PermanentDrawerLeft