import React, { useEffect, useState } from "react";
import { Divider, NativeSelect } from "@mui/material";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomButton from "../../../../Components/CustomBtn";
import { CustomGrid, Form, InputField, Paragraph } from "../../../../Styles/style";
import InputForm from "../../../../Components/InputForm";
import { FetchGetData, FetchPostData } from "../../../../Components/FetchData";
const MainCard = () => {
  const [Token, setToken] = useState("SOL USDT");
  const [Amount, setAmount] = useState("");
  const [WalletAddress, setWalletAddress] = useState("");
  const [CardState, setCardState] = useState(true);
  const [OrderID, setOrderID] = useState("");
  const [OrderToken, setOrderToken] = useState("");
  const [SendTo, setSendTo] = useState("");
  const [Status, setStatus] = useState();
  const [StatusText, setStatusText] = useState("");
  const [LastTxnStatus, setLastTxnStatus] = useState("");

  
  const [amountStatus, setamountStatus] = useState("true");
  const [amountCheck, setamountCheck] = useState("true");
  const [count, setCount] = useState(0);
  const [addressCheck, setaddressCheck] = useState("true");
  const [addressStatus, setaddressStatus] = useState("true");
  
  const [value1, setValue1] = useState("true");
  const [value2, setValue2] = useState("true");


  const handelTokenInput = (value) => {
    setToken(value);
  };
  const handelAmountInput = (value) => {
    setAmount(value);
  };
  const handelWalletAddressInput = (value) => {
    setWalletAddress(value);
  };

  const handelCreateOrderClick =async (value) => {
    if (Amount.length === 0) {
      setamountCheck("false");
      // return
    } else if(WalletAddress.length === 0){
      setaddressCheck("false");
    }else{

      const amount = `${Amount} ${Token.split(" ")[0]}`
      const tokenTo = Token.split(" ")[1]
      const requestData = {
        amount,
        tokenTo,
        WalletAddress
      };
      // console.log(requestData)
      const endPoint = "createMixerOrder";
      const method = "POST";
      const result = await FetchPostData(endPoint, requestData, method);
      if (result?.message === "succes") {
       const {id,token,address,status,statusText,amount} =  result?.data
       setOrderID(id)
       setOrderToken(token)
       setSendTo(address)
       setStatus(status)
       setStatusText(statusText)
       setAmount(amount)
       localStorage.setItem('order_data', JSON.stringify({id,token,address,status,statusText,amount}));
       setCardState(false)
      } else if (result?.message === "All fields are required") {
        // setUseramount("");
        // setaddressword("");
        setamountCheck("false");
        setaddressCheck("false");
      } else if (result?.message === "amount is required") {
        // setUseramount("");
        setamountCheck("false");
      } else if (result?.message === "addressword is required") {
        // setaddressword("");
        setaddressCheck("false");
      } else if (result?.message === "amount is not valid") {
        setamountStatus("false");
      } else if (result?.message === "addressword is not valid") {
        setaddressStatus("false");
      } else {
        console.log(result?.message);
      }
    }
  };

  const handelCheckStatus =async () => {
    const storedData = JSON.parse(localStorage.getItem('order_data'));
    const queryString=`?orderId=${storedData?.id}&orderToken=${storedData.token}`
      // console.log(requestData)
      const endPoint = "checkOrderStatus";
      const method = "GET";
      const result = await FetchGetData(endPoint, queryString, method);
      if (result?.message === "succes") {
       const {id,token,address,status,statusText,amount}  =  result?.data
       console.log(`statuses:${status} and ${Status}`);
       if(status !== Status){
        if(status == 5 || status == 4){
          localStorage.removeItem('order_data');
          setLastTxnStatus(statusText)
          localStorage.setItem('last_message', statusText);
          setCardState(true)
        }else{
          setOrderID(id)
          setOrderToken(token)
          setSendTo(address)
          setStatus(status)
          setStatusText(statusText)
          setAmount(amount)
          localStorage.setItem('order_data', JSON.stringify({id,token,address,status,statusText,amount}));
        }
        
       }
       
      } else if (result?.message === "All fields are required") {
        // setUseramount("");
        // setaddressword("");
        setamountCheck("false");
        setaddressCheck("false");
      } else if (result?.message === "amount is required") {
        // setUseramount("");
        setamountCheck("false");
      } else if (result?.message === "addressword is required") {
        // setaddressword("");
        setaddressCheck("false");
      } else if (result?.message === "amount is not valid") {
        setamountStatus("false");
      } else if (result?.message === "addressword is not valid") {
        setaddressStatus("false");
      } else {
        console.log(result?.message);
      }
  };
useEffect(()=>{
  const storedData = JSON.parse(localStorage.getItem('order_data'))
  console.log("stored dtadadadad",storedData);
  if(storedData){
    const {id,token,address,status,statusText,amount} = storedData
    if(status == 5 || status == 4){
      localStorage.removeItem('order_data');
      localStorage.setItem('last_message', statusText);
    }else{
      setOrderID(id)
      setOrderToken(token)
      setSendTo(address)
      setStatus(status)
      setStatusText(statusText)
      setAmount(amount)
      setCardState(false)
      handelCheckStatus()
    }
  }else{
    const storedData = localStorage.getItem('last_message');
    
    setLastTxnStatus(storedData)
    setCardState(true)
  }
})
  useEffect(() => {
    if (Amount === "" && count !== 0) {
      setValue1("");
      setamountStatus("true");
    } else {
      setamountStatus("true");
      setamountCheck("true");
      setValue1("true");
    }
    setCount(2);
  }, [Amount]);
  useEffect(() => {
    if (WalletAddress === "" && count !== 0) {
      setValue2("");
      setaddressStatus("true");
    } else {
      setaddressStatus("true");
      setaddressCheck("true");
      setValue2("true");
    }
    setCount(2);
  }, [WalletAddress]);


  return (
    <CustomGrid
      bgcolor="#000"
      item
      container
      sx={{ borderRadius: "14px", border: "1px solid #19FB9B" }}
      ai="start"
      fd="column"
      p="20px"
      g={CardState ? "20px" : "13px"}
    >
      {CardState ? (
        <>
        {
          LastTxnStatus ? <CustomGrid item xs={12} jc="space-between" g="5px">
          <Paragraph>Last Transaction Status</Paragraph>
          <Paragraph>{LastTxnStatus}</Paragraph>
        </CustomGrid> :""
        }
          <CustomGrid item xs={12} ai="start" fd="column" g="5px">
            <Paragraph>Token</Paragraph>
            <Form  fullWidth>
              <NativeSelect
                defaultValue={Token}
                onChange={(e) => handelTokenInput(e.target.value)}
                inputProps={{
                  name: "category",
                  id: "uncontrolled-native",
                }}
              >
                <option value="SOL USDT">SOL → USDT</option>
                <option value="SOL ETH">SOL → ETH</option>
              </NativeSelect>
            </Form>
          </CustomGrid>
          <CustomGrid item xs={12} ai="start" fd="column" g="5px">
            <Paragraph>Amount</Paragraph>
            <CustomGrid
              item
              contaienr
              xs={12}
              p="0 5px 0 0"
              sx={{ border: "1px solid #19fb9b", borderRadius: "20px" }}
            >
              <CustomGrid item xs={10}>
                <InputField
                  w="100%"
                  w599="100%"
                  b="none"
                  type="number"
                  placeholder="0.5"
                  value={Amount}
                  onChange={(e) => handelAmountInput(e.target.value)}
                />
              </CustomGrid>
              <CustomGrid item xs={2}>
                <Paragraph c="#19fb9b">SOL</Paragraph>
              </CustomGrid>
            </CustomGrid>
                  <InputForm
                    value={Amount}
                    value1={value1}
                    amountCheck={amountCheck}
                    amountStatus={amountStatus}
                  />
          </CustomGrid>
          <CustomGrid item xs={12} ai="start" fd="column" g="5px">
            <Paragraph>Enter Receiving Wallet Address</Paragraph>
            <InputField
              w="100%"
              w599="100%"
              placeholder="Enter Wallet Address"
              value={WalletAddress}
              onChange={(e) => handelWalletAddressInput(e.target.value)}
              />
              <InputForm
                  value={WalletAddress}
                  addressCheck={addressCheck}
                  value1={value2}
                  addressStatus={addressStatus}
                />
          </CustomGrid>
          <CustomGrid item xs={12} ai="start" fd="column" g="5px">
            <CustomButton
              w="calc(100%)"
              m="0"
              b="1px solid #19fb9b"
              c="#19FB9B"
              hc="#19FB9B"
              bgc="#19fb9d2d"
              hbgc="#19fb9d78"
              fs="14px"
              br="20px"
              onClick={()=>handelCreateOrderClick()}
            >
              Create Order
            </CustomButton>
          </CustomGrid>
        </>
      ) : (
        <>
          <CustomGrid item xs={12} jc="space-between" >
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center"}}>Order ID:</Paragraph>
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center",textDecoration: "underline"}} c="#19fb9b" >
              {OrderID} <FileCopyIcon onClick={()=> navigator.clipboard.writeText(OrderID)} sx={{cursor:"pointer",fontSize:"18px"}}/>
            </Paragraph>
          </CustomGrid>
          <CustomGrid item xs={12} jc="space-between" >
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center"}}>Order Token:</Paragraph>
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center",textDecoration: "underline"}} c="#19fb9b">
              {OrderToken}<FileCopyIcon onClick={()=> navigator.clipboard.writeText(OrderToken)} sx={{cursor:"pointer",fontSize:"18px"}}/>
            </Paragraph>
          </CustomGrid>
          <CustomGrid item xs={12} jc="space-between" >
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center"}}>Please Send:</Paragraph>
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center"}}>
              {Amount} Sol
            </Paragraph>
          </CustomGrid>
          <CustomGrid item xs={12} jc="space-between" >
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center"}}>Send To:</Paragraph>
              {
                Status !== 4 ? 
                <Paragraph fs="12px" sx={{display:"flex",alignItems:"center",textDecoration: "underline"}} c="#19fb9b">

                  {SendTo}<FileCopyIcon onClick={()=> navigator.clipboard.writeText(SendTo)} sx={{cursor:"pointer",fontSize:"18px"}}/>
                </Paragraph> :""
              }
          </CustomGrid>
          <CustomGrid item xs={12} jc="space-between" >
            <Paragraph fs="12px" c="red" sx={{display:"flex",alignItems:"center"}}>Warning:</Paragraph>
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center"}}>
                Send within next 30 minutes or your order will expire.
            </Paragraph>
          </CustomGrid>
          <CustomGrid item xs={12} ai="center" jc="start" >
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center"}}>Waiting 🕕</Paragraph>
          </CustomGrid>
          <Divider sx={{width:"100%",height:"1px",background:"#fff"}} />
          <CustomGrid item xs={12} jc="space-between" >
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center"}}>Status</Paragraph>
            <Paragraph fs="12px" sx={{display:"flex",alignItems:"center"}}>{StatusText}<RefreshIcon onClick={()=> handelCheckStatus()} 
                  sx={{
                      color:"#19FB9B",
                      background:"#19fb9d2d",
                      borderRadius:"20px",
                      border:"1px solid #19FB9B",
                      padding:"1px",
                      marginLeft:"5px",
                      cursor:"pointer"}}/>
              </Paragraph> 
          </CustomGrid>
          {
            Status === 4 ? 
                <CustomGrid item xs={12} jc="space-between" >
                <Paragraph fs="12px" sx={{display:"flex",alignItems:"center"}}>Receiving Transaction</Paragraph>
                <Paragraph fs="12px" sx={{display:"flex",alignItems:"center",textDecoration: "underline"}} c="#19fb9b">
                ...............................<FileCopyIcon onClick={()=> navigator.clipboard.writeText("...............................")} sx={{cursor:"pointer",fontSize:"18px"}}/>
                </Paragraph>
              </CustomGrid> : ""
          }
        </>
      )}
    </CustomGrid>
  );
};

export default MainCard;
