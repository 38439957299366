import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";

import telegram from "../../../../assets/images/telegram.png";
import twitter from "../../../../assets/images/twitter.png";
import missionVideo from "../../../../assets/images/missionVideo.mp4";
import {
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import Tiker from "../../marquetag";
import { MenuLink } from "../../LockerMenu/styles";
const SectionWraper = styled.div`
  overflow: hidden;
  min-height: auto;
  background-color: #000;
  width: 100%;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: auto;
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const MissionSection = () => {
  return (
    <>
      <SectionWraper id="mission">
        <Tiker />
        <InnerContinerWraper sx={{ paddingY: "50px" }}>
          <CustomGrid container jc="center">
            <CustomGrid container item xs={12} sm={6}>
              <video src={missionVideo} style={{width:"100%"}} autoPlay loop muted /> 
            </CustomGrid>
            <CustomGrid container item xs={12} sm={6} fd="column" ai="start" g="30px">
              <CustomGrid
                item
                xs={12}
                fd="column"
                ai="start"
                g="30px"
              >
                <SectionHeading ta="left">Our Mission</SectionHeading>
                <Paragraph textAlign="left">
                  At AnonSol, our mission is to redefine financial privacy on
                  the Solana blockchain. We are committed to providing users
                  with a secure and anonymous platform, empowering them to
                  navigate the decentralized world with confidence. <br />
                  <br />
                  Through cutting-edge technology and a dedication to privacy,
                  we aim to revolutionize the way individuals interact with
                  their finances.
                </Paragraph>
              </CustomGrid>
              <CustomGrid item xs={12} g="10px" jc="start">
                <Paragraph>Follow Us:</Paragraph>
                <MenuLink disp="grid" sx={{border:"1px solid #fff !important", display:"grid",placeItems:"center",borderRadius:"5px"}} p='4px' hbgc="#9747FD" target="blank"  href='https://twitter.com/anonsoltoken'>
                  <DataImage mw='20px' src={twitter}/>
                </MenuLink>
                <MenuLink disp="grid" sx={{border:"1px solid #fff !important", display:"grid",placeItems:"center",borderRadius:"5px"}} p='4px' hbgc="#9747FD" target="blank"  href='https://t.me/anonsoltoken'>
                  <DataImage mw='20px' src={telegram}/>
                </MenuLink>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </SectionWraper>
    </>
  );
};

export default MissionSection;
