import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import PlusIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';

const AccordianTypo = styled(Typography)`
  color: #ffffff;
  font-size: 16px;
  font-family: PoppinsM;
`;

const AccordianDetail = styled(Typography)`
  color: #ffffff;
  font-size: 14px;
  font-family: PoppinsR;
  padding: 0 5px;
`;

const StyledAccordion = styled(Accordion)`
  background: linear-gradient(to bottom,#1B1622,#000000);
  border-radius: 14px;
  padding: 0px 15px;
  margin: 20px 0px;
  border-bottom: none;
  &:first-of-type {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  &:last-of-type {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  &:hover {
    background: #8E40ED; 
  }
`;

const AccordionWrapper = styled(AccordionSummary)`
  padding: 5px 5px;
`;

const StyledIcon = styled.div`
  color: white;
`;

export default function Accordian() {
  const [panel1Open, setPanel1Open] = React.useState(false);
  const [panel2Open, setPanel2Open] = React.useState(false);
  const [panel3Open, setPanel3Open] = React.useState(false);

  const HandlePanel1Change = () => {
    setPanel1Open(!panel1Open);
  };
  const HandlePanel2Change = () => {
    setPanel2Open(!panel2Open);
  };
  const HandlePanel3Change = () => {
    setPanel3Open(!panel3Open);
  };

  return (
    <div>
      <StyledAccordion
        expandIcon={panel1Open}
        onChange={HandlePanel1Change}
      >
        <AccordionWrapper
          expandIcon={
            panel1Open ? (
              <StyledIcon as={MinusIcon} />
            ) : (
              <StyledIcon as={PlusIcon} />
            )
          }
        >
          <AccordianTypo>
          What is AnonSol's primary focus in the decentralized finance space?
          </AccordianTypo>
        </AccordionWrapper>
        <AccordionDetails sx={{ padding: '8px 0px 16px' }}>
          <AccordianDetail>
          AnonSol is dedicated to establishing a private financial ecosystem on the Solana blockchain. Our focus is on offering users anonymous virtual and physical debit cards, ensuring privacy in transactions, and developing a crypto mixer for covert token transfers.
          </AccordianDetail>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expandIcon={panel2Open}
        onChange={HandlePanel2Change}
      >
        <AccordionWrapper
          expandIcon={
            panel2Open ? (
              <StyledIcon as={MinusIcon} />
            ) : (
              <StyledIcon as={PlusIcon} />
            )
          }
        >
          <AccordianTypo>
          How does AnonSol ensure tax-free transactions for its users?
          </AccordianTypo>
        </AccordionWrapper>
        <AccordionDetails sx={{ padding: '8px 0px 16px' }}>
          <AccordianDetail>
          AnonSol on Solana is a tax-free DeFi project providing users with private financial services, including anonymous virtual and physical debit cards. Our innovative revenue model involves introducing a nominal fee for our services, generating revenue as the project scales, thereby supporting growth and profitability while ensuring users retain the full value of their transactions.
          </AccordianDetail>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expandIcon={panel3Open}
        onChange={HandlePanel3Change}
      >
        <AccordionWrapper
          expandIcon={
            panel3Open ? (
              <StyledIcon as={MinusIcon} />
            ) : (
              <StyledIcon as={PlusIcon} />
            )
          }
        >
          <AccordianTypo>
          What sets AnonSol apart from other DeFi projects?
          </AccordianTypo>
        </AccordionWrapper>
        <AccordionDetails sx={{ padding: '8px 0px 16px' }}>
          <AccordianDetail>
          AnonSol stands out by providing a comprehensive solution for privacy in finance. Our offering includes anonymous virtual and physical debit cards, an upcoming crypto mixer for covert transactions, and the unique feature of tax-free operations, setting us apart as a leader in the decentralized finance landscape.
          </AccordianDetail>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  );
}
