import { Grid } from "@mui/material";
import * as React from "react";
import {
  CustomGrid,
  Paragraph,
  SectionHeading,
  VIDEO,
} from "../../Styles/style";
import BgVideo from '../../../assets/images/headervideo.mp4';
import MainCard from "./Components/mainCard";
import styled from "@emotion/styled";
import PermanentDrawerLeft from "../../Components/SideBar";
const ContainerGrid = styled(Grid)`
  display: flex;
  align-items: start;
  justify-content: start;
  min-height: calc(100vh - 150px);
  background-color: #53535311;
  backdrop-filter: blur(15px);
  border-radius: 20px;
`;
export default function Mixer() {
 
  return (
    <>
      
    <VIDEO muted loop autoPlay>
      <source src={BgVideo} />
    </VIDEO>
      <PermanentDrawerLeft>
        <ContainerGrid container jc="start" p="30px 20px">
          <CustomGrid 
            contaienr
            item
            xs={12}
            sm={10}
            md={5}
            ai="start"
            fd="column"
            g="10px"
          >
            <SectionHeading fs="30px" variant="h4" ta="left">
              Mixer
            </SectionHeading>
            <Paragraph variant="body2" p="0 0 10px">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam.
            </Paragraph>
            <MainCard/>
          </CustomGrid>
        </ContainerGrid>
      </PermanentDrawerLeft>
    </>
  );
}
